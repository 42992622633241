<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header v-show="categoryMode" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                <p v-if="!categoryMode" class="title">{{ getCommonText('decoding') }}. {{ currCategory[categoryFieldNames[lng]] }}</p>
            </div>
            <div class="right-content">
                <template v-if="categoryMode">
                    <div class="filter-actions filter-actions-flex">
                        <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="budgetForm"
                            :header="header"
                            :isLoad="isLoad"
                            @keyPress="keyPress"
                        />
                        <b-button variant="primary" @click="onAddNewCat" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    </div>
                </template>
                <template v-else>
                    <b-button variant="light" @click="resetModal">
                        <i class="icon icon-keyboard icon-rotate-180"></i>{{ getCommonText('back_to_list') }}
                    </b-button>
                    <b-button :style="{ 'minWidth': '110px' }" v-if="variantAttribute" variant="primary" @click="$refs.formTemplate.addItem()">
                        <i class="icon icon-plus-circle"></i>{{ getCommonText('add') }}
                    </b-button>
                    <b-button
                        :style="{ 'minWidth': '110px' }" 
                        :disabled="!dataChanged || goodsAreSaving"
                        variant="success"
                        @click="prepareForSave">
                        {{ getCommonText('save') }}
                    </b-button>
                </template>
            </div>
        </div>

        <breadcrumbs-filter v-show="categoryMode" :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>
        <div v-show="categoryMode" class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering"></td>
                    <td class="td-numbering text-center table-success">1</td>
                    <td class="td-numbering text-center table-danger">2</td>
                    <td class="td-numbering text-center table-info">3</td>
                    <td class="td-numbering text-center table-primary">4</td>
                    <td class="td-numbering text-center table-warning">5</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(selection)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            :title="getCommonText('del_selected')" 
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? getCommonText('all_cats') : getCommonText('selected_cats')}`)"/>
                    </div>
                </template>
                <template #cell(selection)="data">
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="data.item.itemToDelete"
                            @input="e => { if (!e) selectAll = false; }"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #cell(action)="data">
                    <button
                        class="table-plus"
                        v-b-modal.goods-modal
                        @click="setCurrCatName(data.item)"
                        :disabled="!(data.item.recipient || data.item.recipient_kk) || data.item.nameUdating"
                    >
                        <i class="icon icon-plus-circle"/>
                    </button>
                </template>
                <template #cell(recipient)="data">
                    <div
                        class="column-width-100 bp-category-name">
                        <div>{{ data.item[categoryFieldNames[lng]] }}</div>
                        <i 
                            v-if="variantAttribute"
                            class="icon icon-pencil-edit thin-blue ml-3 pointer icon-18" 
                            @click="onEditCatName(data.item)"
                        ></i>
                    </div>
                </template>
                <template #cell(average)="data">
                    <div class="text-right">{{ $n(data.item.average) }}</div>
                </template>
                <template #cell(rate)="data">
                    <div class="text-right">{{ $n(data.item.rate) }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ $n(data.item.total) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onAllFilesClick({ ...data.item, name_ru: data.item[categoryFieldNames[lng]]})"
                    >({{data.item.files ? data.item.files.length : 0}})</span>
                </template>
                <template #cell(more)="data">
                    <i class="icon icon-clear table-remove" v-if="variantAttribute" :disabled="deletingCat" @click="deleteItem(`${getCommonText('curr_cat')}`, {...data.item, good_type: data.item}, data.index, true)"></i>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="5">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ isNaN(total) ? 0 : $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
            <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
                <span @click="onAddNewCat"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
            </div>
        </div>
        <template v-if="!categoryMode">
            <b-overlay :show="templateTableOverlay" rounded="sm">
                <budget-forms-template2
                    ref="formTemplate"
                    :goodsData="goodsData"
                    :initialGoodsData="initialGoodsData"
                    :ensTruDict="ensTruDict"
                    :variantAttribute="variantAttribute"
                    :currCategory="currCategory"
                    :lang="lang"
                    :ifAllFieldsFilled="ifAllFieldsFilled"
                    :deleteGood="deleteGood"
                    :getUnitName="getUnitName"
                    :ensTruNamePrt="ensTruNamePrt"
                    :ensTruName="ensTruName"
                    :currUnits="currUnits"
                    :descIsLoading="descIsLoading"
                    :parNameLoading="parNameLoading"
                    :allFiles="allFiles"
                    @setTotals="setTotals"
                    @updateDatas="updateDatas"
                    @save="save"
                    @onDataChanged="onDataChanged"
                    @searchName="searchName"
                    @setIsAllDelete="setIsAllDelete"
                    @deleteSeveralGoods="deleteSeveralGoods"
                    :triggDownloadFile="triggDownloadFile"
                    :showFileUploadModal="showFileUploadModal"
                    :getEnstruByName="getEnstruByName"
                    :loadCurUnits="loadCurUnits"
                    :goodsAreSaving="goodsAreSaving"
                ></budget-forms-template2>
                <div class="table-add" v-if="variantAttribute">
                    <span @click="$refs.formTemplate.addItem()"><i class="icon icon-plus-circle"></i>{{ getCommonText('add') }}</span>
                </div>
            </b-overlay>
        </template>
        <files-updown ref="filesUpdown"
            v-show="categoryMode"
            :header="header"
            :variant-attribute="variantAttribute"
            :load="load"
            :showDeleteLinks="variantAttribute"
            :showDeleteAll="true"
            @getFiles="getFiles"
            @fillFilesList="fillFilesList"
            @restetFilesList="restetFilesList"
            @delelteAttachedFile="delelteAttachedFile"
        ></files-updown>
         <modal-all-files-management
            ref="modalAllFilesManagement"
            :allFiles="allFiles"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            >
        </modal-all-files-management>
        <c-set-b-f-category-name
            ref="setCatNameModal"
            :budgetForm="budgetForm"
            :categoryFieldNames="categoryFieldNames"
            :lng="lng"
            :getCommonText="getCommonText"
            @addItem="addItem"
            @onCatNameChanged="onCatNameChanged"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import BudgetFormsTemplate2 from "./components/budget-forms-template2.vue";
import FormsHandlerMixin from "./mixins/forms-handler-mixin";
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import CSetBFCategoryName from "./components/c-set-bf-category-name.vue";

export default {
    name: 'Form01_143',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        BreadcrumbsFilter, 
        BudgetFormsTemplate2, 
        ModalAllFilesManagement, 
        CBudgetFormsCopyData, 
        FormsDownloadReprt,
        CSetBFCategoryName
    },
    mixins: [FormsHandlerMixin],
    data() {
        return {
            form: {
                code: '01-143',
                name_kk: 'Мүлiктiк керек-жарақ заттарын сатып алу, тiгу және жөндеу және басқа да пiшiмдiк және арнайы киiм-кешектердi сатып алуға арналған шығыстарды есептеу',
                name_ru: 'Приобретение, пошив и ремонт предметов вещевого имущества и другого форменного и специального обмундирования'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            categoryFieldNames: {ru: "recipient", kk: "recipient_kk"}
        };
    },
    mounted() {
        this.progress = 15;
    },
    methods: {
        itemUpdate(item, nameRu, nameKk) {
            this.$set(item, 'recipient', nameRu);
            this.$set(item, 'recipient_kk', nameKk);
            this.$set(item, 'code', this.uuidv4());
            this.$set(item, 'average', 0);
            this.$set(item, 'rate', 0);
            this.$set(item, 'total', 0);
            this.$set(item, 'newCat', true);
            this.$set(item, 'nameUdating', false);
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'rowToSave', true);
            this.$set(item, 'oldName', nameRu);
            this.$set(item, 'oldName_kk', nameKk);
        }, // заполнение полей при добавлении новой категории

        getFiles(data) {
            this.files = data;
        },

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadCategoryData() {
            this.load = true;
            this.budgetForm.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat/' + JSON.stringify(this.header));
                    if (response.status === 200) {
                        const values = await response.json();
                        values.forEach((val, i) => {
                            const item = {
                                id: i,
                                recipient: val.recipient,
                                recipient_kk: val.recipient_kk,
                                code: val.category_id,
                                category_id: val.category_id,
                                average: val.sum_amount,
                                rate: parseFloat(val.avg_price.toFixed(2)),
                                total: parseFloat((val.sum_total / 1000).toFixed(3)),
                                newCat: false,
                                oldName: val.recipient,
                                oldName_kk: val.recipient_kk,
                                nameUdating: false,
                                files: this.getUnicFilesArr(val),
                                itemToDelete: false,
                            };
                            this.$set(item, 'unit', null);
                            this.budgetForm.push(item);
                        });
                        this.budgetForm.sort((a, b) => {
                            const nameField = this.categoryFieldNames[this.lng];
                            const aField = a[nameField] ? a[nameField].toLowerCase() : '';
                            const bField = b[nameField] ? b[nameField].toLowerCase() : '';
                            return aField > bField ? 1 : -1
                        });
                    }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadCategoryData()`, error.toString());
            }
            this.load = false;
        },

        prepareData(val) {
                this.$set(val, 'good_type', val.recipient);
                this.$set(val, 'price', val.rate);
                this.$set(val, 'amount', val.average);
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        fillDataForSave(item, row) {
            // в разных формах может отличаться
            this.$set(item, 'recipient', this.currCategory.recipient);
            this.$set(item, 'recipient_kk', this.currCategory.recipient_kk);
            this.$set(item, 'code', this.currCategory.code);
            this.$set(item, 'average', parseFloat(row.amount));
            this.$set(item, 'rate', parseFloat(row.price));
        }, // заполнение полей при подготовке к сохранению товаров

        getCodeFieldName() {
            return 'code';
        }, // при необходимости заменить в родителе

        onCatNameChanged(row) {
            this.$set(row, 'rowToSave', true);
            this.onNameChanged(row, this.categoryFieldNames);
        }

    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        },

        getFormText() {
            return this.setFormText('form_01_143.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'selection',
                        label: ' '
                    },
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'recipient',
                        label: this.getFormText('receiver_name')
                    },
                    {
                        key: 'average',
                        label: this.getFormText('annual_count')
                    },
                    {
                        key: 'rate',
                        label: this.getFormText('norm_per_unit')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('expense_sum')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        }
    }
};
</script>

<style scoped>
    .error input {
        padding-left: 25px;
    }
    .filter-actions-flex {
        display: flex;
    }
    .table-plus {
        border: none;
    }
    .bp-category-name {
        display: flex;
        justify-content: space-between;
    }
    .bp-category-name .icon-pencil-edit {
        align-self: center;
    }

</style>