import { render, staticRenderFns } from "./budget-forms-template2.vue?vue&type=template&id=cd25045a&scoped=true&"
import script from "./budget-forms-template2.vue?vue&type=script&lang=js&"
export * from "./budget-forms-template2.vue?vue&type=script&lang=js&"
import style0 from "./budget-forms-template2.vue?vue&type=style&index=0&id=cd25045a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd25045a",
  null
  
)

export default component.exports